<template>
<div>
  <b-dropdown
    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
    text="Acties"
    right
    :disabled="loading"
    variant="flat-primary"
    :no-caret="small"
    v-if="showEdit || (user.email && !user.uid)"
    
  >
    <template #button-content>
      <b-spinner small class="mr-1" v-if="loading" />
      <feather-icon v-if="small && !loading" icon="MoreVerticalIcon" />
      <span v-else-if="!small">Acties</span>
    </template>
    <b-dropdown-item v-if="showEdit" :to="{ name: 'users-edit', params: { uoid: user.uoid } }">
      <feather-icon class="mr-1" icon="FileTextIcon" />
      Bewerken</b-dropdown-item
    >
    <b-dropdown-item v-if="user.email && !user.uid" @click="sendInvite">
      <feather-icon class="mr-1" icon="SendIcon" />
      {{ user.firstname || user.displayName }} uitnodigen om Tapp te
      gebruiken</b-dropdown-item
    >
  </b-dropdown>
</div>
</template>

<script>
import { BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import userVerification from "@/mixins/userVerification";
export default {
  mixins: [userVerification],
  components: {
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    user: {
      required: true,
    },
    small: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    sendInvite() {
      this.loading = true;
      this.inviteUser(
        this.user.uoid ||
          this.user.organisations[this.$store.state.organisation.id].uoid
      ).then(() => (this.loading = false));
    },
  },
  computed: {
      showEdit(){
          return this.$route.name != 'users-edit';
      }
  }
};
</script>

<style>
</style>