<template>
<div class="mr-1">
  <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              text="Microsoft 365"
              size="sm"
              
              :disabled="loading"
              variant="outline-primary"
            >
              <b-dropdown-item @click="resetPasswords">
                <feather-icon icon="RefreshCwIcon" />
                Office 365 wachtwoorden opnieuw instellen
              </b-dropdown-item>
              <b-dropdown-item @click="createAccounts">
                <feather-icon icon="SendIcon" />
                Office 365 accounts aanmaken
              </b-dropdown-item>
            </b-dropdown>
            
            <b-spinner v-if="loading" class="ml-1" small />
</div>
</template>
<script>

import { BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import userVerification from "@/mixins/userVerification";
import mainMixin from "@/mixins/mainMixin";

import { db, functions } from '@/firebase/firebaseConfig';

import XLSX from "xlsx";
import moment from "moment";

export default {
  mixins: [userVerification, mainMixin],
  components: {
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
      users: {}
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
      nonM365Users(){
          return this.users.filter((el) => el.m365 == null)
      }
  },
  methods: {
    async resetPasswords(){
      this.loading = true;
      this.loading = true;
        functions
          .httpsCallable("resetM365PasswordForUsers", {timeout: 540000})({
            orgId: this.$store.state.organisation.id,
            users: this.users,
            
          })
          .then(async (res) => {
              const result = res.data;
              var now = new Date();
             var wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: `Microsoft 365 gebruikers`,
                    Subject: `Geexporteerd op ${this.formatDate(now)}`,
                    Author: this.$store.state.organisation.fullName,
                    CreatedDate: new Date(),
                };
                wb.SheetNames.push("Gebuikers");
                
                var ws_data = [['Voornaam', 'Naam', 'Weergavenaam', 'Groep', 'Gebruikersnaam', 'Wachtwoord']];
                for(var i = 0; i<this.users.length; i++){
                    const u = this.users[i];
                    ws_data.push([u.firstname, u.lastname, u.displayName, u.groupIds && u.groupIds[0] && u.groups[u.groupIds[0]] ? u.groups[u.groupIds[0]].name : '', u.m365 ? u.m365.userPrincipalName : '', u.m365 ? result[i].password : '']);
                }
                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets['Gebuikers'] = ws;
                XLSX.writeFile(
                    wb,
                    `${now.getFullYear()}${now.getMonth()}${now.getDate()}-m365-gebruikers.xlsx`
                );
              this.loading = false;
          }).catch((error) => {
              console.log(error);
              this.loading = false;
          });
    },
    async createAccounts(){
        this.loading = true;
        functions
          .httpsCallable("createM365Users", {timeout: 540000})({
            orgId: this.$store.state.organisation.id,
            users: this.nonM365Users,
            
          })
          .then(async (res) => {
              const result = res.data;
              var now = new Date();
             var wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: `Nieuwe Microsoft 365 gebruikers`,
                    Subject: `Geexporteerd op ${this.formatDate(now)}`,
                    Author: this.$store.state.organisation.fullName,
                    CreatedDate: new Date(),
                };
                wb.SheetNames.push("Gebuikers");
                
                var ws_data = [['Voornaam', 'Naam', 'Weergavenaam', 'Groep', 'Gebruikersnaam', 'Wachtwoord']];
                for(var i = 0; i<this.users.length; i++){
                    const u = this.users[i];
                    ws_data.push([u.firstname, u.lastname, u.displayName, u.groupIds ? u.groups[u.groupIds[0]].name : '', result[i].userPrincipalName, result[i].password]);
                }
                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets['Gebuikers'] = ws;
                XLSX.writeFile(
                    wb,
                    `${now.getFullYear()}${now.getMonth()}${now.getDate()}-nieuwe-m365-gebruikers.xlsx`
                );
              this.loading = false;
          }).catch((error) => {
              console.log(error);
              this.loading = false;
          });
        
    },
    formatDate(date, format = "dd D/MM/YY (HH:mm)") {
      return moment(date).format(format);
    },
  },
};
</script>