<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <!-- User Info: Input Fields -->
      <b-form @submit.prevent="createUser">
        <b-row>
          <!-- Field: Firstname -->
          <b-col cols="12" md="12" v-if="!config.fields || config.fields['firstname'] == true">
            <b-form-group label="Voornaam" label-for="firstname">
              <b-form-input
                id="firstname"
                :class="$v.userData.firstname.$error ? 'is-invalid' : ''"
                v-model="userData.firstname"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Name -->
          <b-col cols="12" md="12" v-if="!config.fields || config.fields['lastname'] == true">
            <b-form-group label="Naam" label-for="name">
              <b-form-input
                id="name"
                @blur="
                  userData.displayName =
                    userData.firstname + ' ' + userData.lastname
                "
                :class="$v.userData.lastname.$error ? 'is-invalid' : ''"
                v-model="userData.lastname"
              />
            </b-form-group>
          </b-col>

          <!-- Field: DisplayName -->
          <b-col cols="12" md="12" v-if="!config.fields || config.fields['displayName'] == true">
            <b-form-group label="Weergavenaam" label-for="displayName" >
              <b-form-input
                id="displayName"
                :class="$v.userData.displayName.$error ? 'is-invalid' : ''"
                v-model="userData.displayName"
                type="text"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Birth Date -->
          <b-col cols="12" md="12" v-if="!config.fields || config.fields['birthday'] == true">
            <b-form-group label="Geboortedatum" label-for="birth-date">
              <cleave
                id="birthday"
                class="form-control"
                :raw="false"
                :options="cleaveBirth"
                :class="$v.userData.birthday.$error ? 'is-invalid' : ''"
                :disabled="loading"
                v-model="userData.birthday"
                placeholder="dd/mm/jjjj"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col cols="12" md="12" v-if="!config.fields || config.fields['gender'] == true">
            <b-form-group
              label="Geslacht"
              label-for="gender"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="gender"
                v-model="userData.gender"
                :class="$v.userData.gender.$error ? 'is-invalid' : ''"
                :options="genderOptions"
                value="male"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Type -->
          <b-col cols="12" md="12" v-if="!config.fields || config.fields['type'] == true">
            <b-form-group label="Type" label-for="type" label-class="mb-1">
              <v-select
                v-model="userData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userRoles"
                :class="$v.userData.type.$error ? 'is-invalid' : ''"
                :getOptionLabel="(option) => getRoleSingular(option)"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <h6 class="section-label mb-1 mt-2">
              Persoonlijke Contactgegevens
            </h6>
          </b-col>

          <!-- phone -->
          <b-col cols="12" class="mb-2" v-if="!config.fields || config.fields['phone'] == true">
            <b-row :key="k" v-for="(p, k) in userData.phones">
              <b-col cols="10">
                <b-form-group
                  label-for="phone"
                  :label="'Telefoonnummer ' + (k + 1)"
                >
                  <cleave
                    class="form-control"
                    :options="cleavePhone"
                    :disabled="loading"
                    v-model="userData.phones[k]"
                    :class="
                      $v.userData.phones.$each[k].$error ? 'is-invalid' : ''
                    "
                    placeholder="Telefoonnummer"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removePhone(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addPhone"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Telefoonnummer toevoegen</span>
            </b-button>
          </b-col>
          <!-- phone -->

          <!-- email -->
          <b-col cols="12" class="mb-2" v-if="!config.fields || config.fields['email'] == true">
            <b-row :key="k" v-for="(p, k) in userData.emails">
              <b-col cols="10">
                <b-form-group label-for="email" :label="'E-mail ' + (k + 1)">
                  <b-form-input
                    class="form-control"
                    v-model="userData.emails[k]"
                    :name="'email-' + k"
                    :class="
                      $v.userData.emails.$each[k].$error ? 'is-invalid' : ''
                    "
                    :disabled="loading"
                    placeholder="E-mail"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeEmail(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addEmail"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>E-mail toevoegen</span>
            </b-button>
          </b-col>
          <!-- email -->

          <!-- email -->
          <b-col cols="12" class="mb-2" v-if="!config.fields || config.fields['address'] == true">
            <b-row :key="k" v-for="(p, k) in userData.addresses">
              <b-col sm="12">
                <h6 class="form-label mb-1 mt-2">
                  {{ k == 0 ? "Hoofdadres" : "Adres " + (k + 1) }}
                </h6>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Straat">
                  <b-form-input
                    class="form-control"
                    v-model="userData.addresses[k].street"
                    :class="
                      $v.userData.addresses.$each[k].street.$error
                        ? 'is-invalid'
                        : ''
                    "
                    :disabled="loading"
                    placeholder="Straat"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Nr.">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].housenumber"
                    :class="
                      $v.userData.addresses.$each[k].housenumber.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Nr."
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Bus">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].addition"
                    :class="
                      $v.userData.addresses.$each[k].addition.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Bus"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeAddress(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Postcode">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].postal"
                    :class="
                      $v.userData.addresses.$each[k].postal.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Postcode"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Plaats">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].city"
                    :class="
                      $v.userData.addresses.$each[k].city.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Plaats"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addAddress"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Adres toevoegen</span>
            </b-button>
          </b-col>
          <b-col sm="12" v-if="customfields.length > 0">
            <h6 class="section-label mb-1 mt-2">Aangepaste velden</h6>
          </b-col>
          <!-- Field: Firstname -->
          <b-col
            cols="12"
            :key="'field-' + key"
            v-for="(field, key) in customfields"
            
          >
            <b-form-group
              v-if="field.type == 'string'"
              :label="field.name"
              :label-for="field.id"
            >
              <b-form-input
                :id="field.id"
                v-model="userData.custom[field.id]"
              />
            </b-form-group>
            <b-form-checkbox
              v-else-if="field.type == 'boolean'"
              v-model="userData.custom[field.id]"
              switch
              inline
              class="custom-control-success"
            >
              {{ field.name }}
            </b-form-checkbox>
            <b-form-group
              v-if="field.type == 'number'"
              :label="field.name"
              :label-for="field.id"
            >
              <b-form-input
                :id="field.id"
                type="number"
                v-model="userData.custom[field.id]"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :disabled="loading"
        >
          Opslaan
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          @click="resetData"
          :disabled="loading"
        >
          Annuleren
        </b-button>
      </b-form>
    </div>
  </b-overlay>
</template>

<script>
import { db } from "@/firebase/firebaseConfig";
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BOverlay,
  BFormFile,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "cleave.js/dist/addons/cleave-phone.be";

import Ripple from "vue-ripple-directive";

import mainMixin from "@/mixins/mainMixin";

export default {
  mixins: [mainMixin],
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
    Cleave,
    BOverlay,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  computed: {
    customfields() {
      var res = [];
      if (
        this.$store.state.organisation &&
        this.$store.state.organisation.customFields
      ) {
        this.$store.state.organisation.customFields.forEach((f) => {
          if (f.enabled && (!this.config.fields || this.config.fields[f.id] == true)) {
            res.push(f);
          }
        });
      }
      return res;
    },
  },
  data() {
    return {
      userData: {
        groupIds: [],
        groups: {},
        organisationIds: [this.$store.state.organisation.id],
        organisations: {},
        type: "member",
        displayName: null,
        firstname: null,
        lastname: null,
        birthday: null,
        gender: "M",
        phone: null,
        phones: [],
        email: null,
        emails: [],
        address: null,
        addresses: [],
        custom: {},
      },
      loading: false,
      config: {},
      cleaveBirth: {
        date: true,
        delimiter: "/",
        datePattern: ["d", "m", "Y"],
      },
      cleavePhone: {
        phone: true,
        phoneRegionCode: "BE",
      },
    };
  },
  created(){
    this.getSignUpConfig();
  },
  methods: {
    avatarText: avatarText,
    inputImageRenderer() {
      const file = this.profileFile;
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.profilePic = reader.result;
          this.uploadFile();
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    async getSignUpConfig() {
      var snap = await db
        .collection("organisations")
        .doc(this.$store.state.organisation.id)
        .collection("config")
        .doc("sign-up")
        .get();
      if (snap.exists) {
        this.config = snap.data();
        if (this.config.fields) {
          this.checkCustomFields();
        }        
      } 
    },
    checkCustomFields() {
      if (this.$store.state.organisation.customFields) {
        this.$store.state.organisation.customFields.forEach((f) => {
          if (this.config.fields[f.id] == null) {
            this.config.fields[f.id] = true;
          }
        });
      }
    },
    resetData() {
      this.$v.$reset();
      this.$emit("resetData", null);
    },
    createUser() {
      if (!this.$v.$invalid) {
        this.loading = true;
        this.userData.uoid = this.generateUUID();
        this.userData.organisations[this.$store.state.organisation.id] = {
          shortName: this.$store.state.organisation.shortName,
          type: this.userData.type,
          uoid: this.userData.uoid,
        };
        this.userData.email = this.userData.emails[0] ?? null;
        this.userData.phone = this.userData.phones[0] ?? null;
        this.userData.address = this.userData.addresses[0] ?? null;
        this.$store
          .dispatch("userManagement/updateUser", {
            orgId: this.$store.state.organisation.id,
            user: this.userData,
          })
          .then(() => {
            this.$emit("input", this.userData);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${this.userData.displayName} toegevoegd!`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((err) => {
            console.error(err);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `error!`,
                text: err.message,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.$v.$touch();
      }
    },
    addPhone() {
      this.userData.phones.push("");
    },
    removePhone(index) {
      this.userData.phones.splice(index, 1);
    },
    addEmail() {
      this.userData.emails.push("");
    },
    removeEmail(index) {
      this.userData.emails.splice(index, 1);
    },
    addAddress() {
      this.userData.addresses.push({});
    },
    removeAddress(index) {
      this.userData.addresses.splice(index, 1);
    },
  },
  validations: {
    userData: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      displayName: {
        required,
      },
      birthday: {},
      gender: {},
      type: {
        required,
      },
      emails: {
        $each: {
          required,
          email,
        },
      },
      phones: {
        $each: {
          minLength: minLength(9),
        },
      },
      addresses: {
        $each: {
          street: {
            minLength: minLength(2),
          },
          housenumber: {
            numeric,
          },
          addition: {},
          postal: {
            numeric,
          },
          city: {},
        },
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
