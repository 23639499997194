<template>
<div>
  <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              text="Geavanceerd"
              size="sm"
              :disabled="loading"
              variant="outline-danger"
            >
              <b-dropdown-item @click="resetAllVerifications">
                <feather-icon icon="RefreshCwIcon" />
                Koppelcodes opnieuw instellen
              </b-dropdown-item>
              <b-dropdown-item @click="sendBulkInvitations">
                <feather-icon icon="SendIcon" />
                Verstuur uitnodiging naar alle inactieve gebruikers met een e-mailadres
              </b-dropdown-item>
            </b-dropdown>
            
            <b-spinner v-if="loading" class="ml-1" small />
</div>
</template>
<script>

import { BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import userVerification from "@/mixins/userVerification";
import mainMixin from "@/mixins/mainMixin";
export default {
  mixins: [userVerification, mainMixin],
  components: {
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async resetAllVerifications(){
      this.loading = true;
      await this.resetVerificationForOrganisation();
      this.loading = false;
    },
    async sendBulkInvitations(){
        this.loading = true;
        await this.inviteAllMembers();
        this.loading = false;
    }
  },
};
</script>