<template>
  <div>
    <div
      class="d-flex flex-column align-center"
      :class="hidden ? 'd-none' : 'd-block'"
    >
      <div v-if="!multiple" ref="qrcode" class="mb-1"></div>
      <div v-else-if="type == 'group'">
        <div :key="d.id" v-for="d in data.ver">
          <div :ref="'qrcode-'+d.id"></div>
        </div>
      </div>
      <div v-else>
        <div :key="d.uoid" v-for="d in data.ver">
          <div :ref="'qrcode-'+d.uoid"></div>
        </div>
      </div>
      <h4 v-if="!multiple" class="text-center">{{ data.ver.code }}</h4>
      <slot name="trigger"></slot>
    </div>
    <div :class="hidden ? 'd-block' : 'd-none'">
      <slot name="trigger"></slot>
    </div>
  </div>
</template>

<script>
import * as UbuntuBold from "@/assets/Ubuntu-bold";
import * as UbuntuRegular from "@/assets/Ubuntu-normal";
import { jsPDF } from "jspdf";
import QRCode from "easyqrcodejs";
import mainMixin from '@/mixins/mainMixin';
export default {
  mixins: [mainMixin],
  props: {
    type: {
      default: "group",
    },
    data: {},
    hidden: {
      default: false,
    },
    multiple: {
      default: false,
    },
    titleAddon:{}
  },
  data() {
    return {
      pdf: null,
      qrs: {},
      playstoreimg: null,
      appstoreimg: null,
      playstoreqrimg: null,
      appstoreqrimg: null,
      registerimg: null,
      logo: null
    };
  },
  created() {
    this.pdf = new jsPDF("p", "pt", "A4");
    this.loadCustomFonts();
  },
  mounted() {
    if(this.type && this.data.ver && this.data.user){
      this.generateQRCode();
    }
  },
  methods: {
    async generatePDF() {
      this.pdf = new jsPDF("p", "pt", "A4");
      switch (this.type) {
        case "group": {
          await this.generateQRCode();
          if (this.multiple) {
            return this.generateGroupsPdf();
          } else {
            return this.generateGroupPdf();
          }
        }
        case "member": {
          await this.generateQRCode();
          return this.generateMemberPDF();
        }
        case "parent": {
          await this.generateQRCode();
          if (this.multiple) {
            return this.generateParentsPDF();
          } else {
            return this.generateParentPDF();
          }
        }
        case "parentLabels": {
          return this.generateLabelsPDF('parent');
        }
        case "memberLabels": {
          return this.generateLabelsPDF('member');
        }
        default: {
          return this.generateGroupPdf();
        }
      }
    },
    base64SvgToBase64Png(originalBase64, width) {
      return new Promise((resolve) => {
        let img = document.createElement("img");
        img.onload = function () {
          document.body.appendChild(img);
          let canvas = document.createElement("canvas");
          let ratio = img.clientWidth / img.clientHeight || 1;
          document.body.removeChild(img);
          canvas.width = width;
          canvas.height = width / ratio;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          try {
            let data = canvas.toDataURL("image/png");
            resolve(data);
          } catch (e) {
            resolve(null);
          }
        };
        img.src = originalBase64;
      });
    },
    loadImageAsync(src) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    },
    loadCustomFonts() {
      this.pdf.setFont("Ubuntu", "bold");
      this.pdf.setFontSize(24);
    },
    async addTappLogo() {
      if(!this.logo){
         this.logo = await this.loadImageAsync("../logo.png");
      }
     
      this.pdf.addImage(this.logo, 20, 20, 120, 46.5306122446);
    },
    setTitle(title) {
      this.pdf.setProperties({ title: title });
    },
    setOpacitiy(amt) {
      this.pdf.saveGraphicsState();
      this.pdf.setGState(new this.pdf.GState({ opacity: amt }));
    },
    resetOpacity() {
      this.pdf.restoreGraphicsState();
    },
    drawRoundedRectangle(x, y, w, h, r, t, c) {
      this.pdf.setFillColor(c);
      this.pdf.roundedRect(x, y, w, h, r, r, t);
    },
    writeText(t, x, y, a, c) {
      this.pdf.setFont("Ubuntu", "normal");
      this.pdf.setFontSize(18);
      this.pdf.setTextColor(c);
      this.pdf.text(t, x, y, null, null, a);
    },
    writeTitle(t, x, y, a, c = "#000000") {
      this.pdf.setFont("Ubuntu", "bold");
      this.pdf.setFontSize(24);
      this.pdf.setTextColor(c);
      this.pdf.text(t, x, y, null, null, a);
    },
    writeh2(t, x, y, a, c = "#1f2185") {
      this.pdf.setFont("Ubuntu", "normal");
      this.pdf.setFontSize(18);
      this.pdf.setTextColor(c);
      this.pdf.text(t, x, y, null, null, a);
    },
    async displayAsyncImage(url, x, y, w, h) {
      const img = await this.loadImageAsync(url);
      return this.pdf.addImage(img, x, y, w, h);
    },
    displayImage(img, x, y, w, h){
      return this.pdf.addImage(img, x, y, w, h);
    },
    async addImageFromRef(ref, x, y, w, h) {
      var d = new XMLSerializer().serializeToString(ref.firstChild);
      var encoded = "data:image/svg+xml;base64," + btoa(d);
      var png = await this.base64SvgToBase64Png(encoded, 200);
      return this.pdf.addImage(png, "PNG", x, y, w, h);
    },
    async addImageFromString(s, x, y, w, h){
      var encoded = "data:image/svg+xml;base64," + btoa(s);
      var png = await this.base64SvgToBase64Png(encoded, 200);
      return this.pdf.addImage(png, "PNG", x, y, w, h);
    },
    async generateQRCode() {
      if (this.multiple) {
        var promises = []
        if(this.type == 'group'){
          this.data.g.forEach((u) => {
          const d = this.data.ver.filter((d) => d.id == u.id)[0];
          if(d){
            promises.push(new Promise((resolve, reject) => {
              var options_object = {
              text: d.url ?? d.code,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              drawer: "svg",
              correctLevel: QRCode.CorrectLevel.M,
              onRenderingEnd: (qrCodeOptions, dataURL) => {
                this.qrs[d.id] = dataURL;
                resolve()
              }
            };
            new QRCode(this.$refs['qrcode-'+d.id], options_object);
            }));
          }
         
          return Promise.all(promises);
        
          
        });
        }
        else { 
          this.data.user.forEach((u) => {
          const d = this.data.ver.filter((d) => d.uoid == u.uoid)[0];
          if(d){
            promises.push(new Promise((resolve, reject) => {
              var options_object = {
              text: d.url ?? d.code,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              drawer: "svg",
              correctLevel: QRCode.CorrectLevel.M,
              onRenderingEnd: (qrCodeOptions, dataURL) => {
                this.qrs[d.uoid ?? d.id] = dataURL;
                resolve()
              }
            };
            new QRCode(this.$refs['qrcode-'+d.uoid], options_object);
            }));
          }
         
          return Promise.all(promises);
        
          
        });
        }
      } else {
       return new Promise((resolve, reject) => { var options_object = {
          text: this.data.ver.url,
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          drawer: "svg",
          correctLevel: QRCode.CorrectLevel.M,
          onRenderingEnd: (qrCodeOptions, dataURL) => {
              this.qrs[this.data.ver.uoid ?? this.data.ver.id] = dataURL;
              resolve()
            }
        };
        new QRCode(this.$refs.qrcode, options_object);
       });
      }
    },
     async generateGroupsPdf() {
        for(var i = 0; i<this.data.g.length; i++){
        const u = this.data.g[i];
        const d = this.data.ver.filter((d) => d.id == u.id)[0];
        if(d && u.name){
          await this.generateGroupPage(u, d, this.$refs['qrcode-'+u.id]);
          if(i + 1 < this.data.g.length){
            this.pdf.addPage();
          }
        }
      }
      return this.pdf.save('Groepsposters');
     },
    async generateGroupPdf() {
      this.setTitle(this.data.g.name);
      await this.generateGroupPage(this.data.g, this.data.ver, this.$refs.qrcode)
      this.pdf.save(this.data.g.name);
    },

    async generateGroupPage(g, ver, qr){
      this.addTappLogo();
      
      this.writeTitle(
        `Volg`,
        this.pdf.internal.pageSize.width / 2,
        100,
        "center",
        "#000000"
      );

      var width = this.pdf.getTextWidth(`${g.name}`);
      this.setOpacitiy(0.2);
      this.drawRoundedRectangle(
        (this.pdf.internal.pageSize.width - width - 20) / 2,
        110,
        width + 20,
        25,
        4,
        "F",
        `${g.color ?? "#1f2185"}`
      );

      this.resetOpacity();

      this.writeTitle(
        `${g.name}`,
        this.pdf.internal.pageSize.width / 2,
        130,
        "center",
        `${g.color ?? "#1f2185"}`
      );

      this.writeTitle(
        `in 3 stappen:`,
        this.pdf.internal.pageSize.width / 2,
        160,
        "center",
        "#000000"
      );

      await this.addSteps(ver, qr);
    },

    async generateMemberPDF() {
      this.addTappLogo();
      this.setTitle(this.data.user.name);
      this.writeTitle(
        `Dag`,
        this.pdf.internal.pageSize.width / 2,
        100,
        "center",
        "#000000"
      );

      var width = this.pdf.getTextWidth(`${this.data.user.displayName}`);
      this.setOpacitiy(0.2);
      this.drawRoundedRectangle(
        (this.pdf.internal.pageSize.width - width - 20) / 2,
        110,
        width + 20,
        25,
        4,
        "F",
        `${this.$store.state.organisation.primaryColor ?? "#1f2185"}`
      );

      this.resetOpacity();

      this.writeTitle(
        `${this.data.user.displayName}`,
        this.pdf.internal.pageSize.width / 2,
        130,
        "center",
        `${this.$store.state.organisation.primaryColor ?? "#1f2185"}`
      );

      this.writeTitle(
        `Koppel je account in 3 stappen:`,
        this.pdf.internal.pageSize.width / 2,
        160,
        "center",
        "#000000"
      );

      await this.addSteps();

      this.pdf.save(this.data.user.displayName);
    },

    async generateParentPage(user, ver, ref) {
      this.addTappLogo();
      this.writeTitle(
        `Aan de ouders van`,
        this.pdf.internal.pageSize.width / 2,
        100,
        "center",
        "#000000"
      );

      var width = this.pdf.getTextWidth(`${user.displayName}`);
      this.setOpacitiy(0.2);
      this.drawRoundedRectangle(
        (this.pdf.internal.pageSize.width - width - 20) / 2,
        110,
        width + 20,
        25,
        4,
        "F",
        `${this.$store.state.organisation.primaryColor ?? "#1f2185"}`
      );

      this.resetOpacity();

      this.writeTitle(
        `${user.displayName}`,
        this.pdf.internal.pageSize.width / 2,
        130,
        "center",
        `${this.$store.state.organisation.primaryColor ?? "#1f2185"}`
      );

      this.writeTitle(
        `Koppel je account in 3 stappen:`,
        this.pdf.internal.pageSize.width / 2,
        160,
        "center",
        "#000000"
      );

      await this.addSteps(ver, ref);

      
    },

    async addSteps(ver, ref) {
      this.writeh2(
        "1. Download Tapp op je smartphone",
        this.pdf.internal.pageSize.width / 2,
        190,
        "center"
      );

      if(!this.appstoreimg){
        this.appstoreimg = await this.loadImageAsync('../appstore.png');
      }
      await this.displayImage(
        this.appstoreimg,
        this.pdf.internal.pageSize.width / 2 - 100,
        340,
        60,
        20
      );
      if(!this.playstoreimg){
        this.playstoreimg = await this.loadImageAsync('../playstore.png');
      }
      await this.displayImage(
        this.playstoreimg,
        this.pdf.internal.pageSize.width / 2 + 35,
        340,
        67.5,
        20
      );
      if(!this.appstoreqrimg){
        this.appstoreqrimg = await this.loadImageAsync('../appstore-qr.png');
      }
      await this.displayImage(
        this.appstoreqrimg,
        this.pdf.internal.pageSize.width / 2 - 130,
        210,
        120,
        120
      );
      if(!this.playstoreqrimg){
        this.playstoreqrimg = await this.loadImageAsync('../playstore-qr.png');
      }
      await this.displayImage(
        this.playstoreqrimg,
        this.pdf.internal.pageSize.width / 2 + 10,
        210,
        120,
        120
      );

      this.writeh2(
        "2. Maak een account",
        this.pdf.internal.pageSize.width / 2,
        400,
        "center"
      );
      if(!this.registerimg){
        this.registerimg = await this.loadImageAsync('../registreren.png');
      }
      await this.displayImage(
        this.registerimg,
        this.pdf.internal.pageSize.width / 2 - 100,
        410,
        200,
        40
      );

      this.writeh2(
        "3. Scan de QR-code",
        this.pdf.internal.pageSize.width / 2,
        500,
        "center"
      );

      await this.addImageFromString(
        this.qrs[ver.uoid ?? ver.id],
        (this.pdf.internal.pageSize.width - 200) / 2,
        (this.pdf.internal.pageSize.height - 200) / 2 + 220,
        200,
        200
      );

      this.pdf.setTextColor("#000000");
      this.pdf.setFont("Ubuntu", "bold");
      this.pdf.setFontSize(24);
      this.pdf.text(
        `${ver.code}`,
        this.pdf.internal.pageSize.width / 2,
        (this.pdf.internal.pageSize.height - 200) / 2 + 450,
        null,
        null,
        "center"
      );
      this.pdf.setDrawColor("#e1e2ee");
      this.pdf.setLineWidth(2);
      this.pdf.roundedRect(
        (this.pdf.internal.pageSize.width - 200) / 2 - 25,
        (this.pdf.internal.pageSize.height - 200) / 2 - 25 + 220,
        250,
        270,
        20,
        20
      );
    },

    async addLabel(row, col, d, u, type){
      this.pdf.setFontSize(12);
      this.pdf.setFont("Ubuntu", "bold");
      this.pdf.text(type == 'parent' ? 'Oudercode Tapp' : 'Koppelcode Tapp', col*200 + 20, row*60 +60 , null, null, "left");
      this.pdf.setFont("Ubuntu", "normal");
      this.pdf.text(("" + u.displayName.substring(0,20) + (u.displayName.length > 20 ? '...' : '' )) ?? "", col*200 + 20, row*60 +75 , null, null, "left");
      this.pdf.setFont("Ubuntu", "bold");
      this.pdf.text(d.code ?? "", col*200 + 20, row*60 + 90, null, null, "left");
      return true;
    },

    async generateParentPDF() {
      this.setTitle(this.data.user.displayName);
      await this.generateParentPage(this.data.user, this.data.ver, this.$refs.qrcode);
      this.pdf.save(this.data.user.displayName);
    },

    async generateParentsPDF() {
      for(var i = 0; i<this.data.user.length; i++){
        const u = this.data.user[i];
        const d = this.data.ver.filter((d) => d.uoid == u.uoid)[0];
        if(d && u.displayName && u.uoid){
          await this.generateParentPage(u, d, this.$refs['qrcode-'+u.uoid]);
          if(i + 1 < this.data.user.length){
            this.pdf.addPage();
          }
        }
      }
      return this.pdf.save('Brieven voor ouders');
    },
    async generateLabelsPDF(type){
      this.setTitle(`Etiketten voor ${type == 'parent' ? 'ouders' : this.getRoleMultiple('member')}${this.titleAddon ? ` ${this.titleAddon}` : ''}`);
      var row = 0;
      var col = 0;
      for(var i = 0; i<this.data.user.length; i++){
        const u = this.data.user[i];
        
        const d = this.data.ver.filter((d) => d.uoid == u.uoid)[0];
        if(d){
         
        await this.addLabel(row, col, d, u, type);

           if(col < 2){
          col++;
        } else {
          col = 0;
          if(row < 12){
            row++;
          } else {
            row = 0;
            this.pdf.addPage();
          }
           
        }
        
        }
       
      }

      return this.pdf.save(`Etiketten voor ${type == 'parent' ? 'ouders' : this.getRoleMultiple('member')}${this.titleAddon ? ` ${this.titleAddon}` : ''}`);
      
      
    }
  },
};
</script>

<style>
</style>