<template>
  <div>
    <b-dropdown
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      text="Downloaden"
      size="sm"
      class="mr-1"
      :disabled="loading"
      variant="outline-primary"
    >
      <pdf-generator
        :data="{ ver: letterData, user: userRecords }"
        :type="type"
        :ref="`pdfGenerator`"
        :multiple="true"
        :titleAddon="titleAddon"
        :hidden="true"
      ></pdf-generator>
      <b-dropdown-item @click="getXLSX">
        <feather-icon icon="DownloadIcon" />
        Download .xlsx lijst
      </b-dropdown-item>
      <b-dropdown-item @click="getVerificationLetters">
        <feather-icon icon="DownloadIcon" />
        Brieven voor ouders downloaden
      </b-dropdown-item>

      <b-dropdown-item @click="getLabels('parent')">
        <feather-icon icon="DownloadIcon" />
        Etiketten voor ouders downloaden
      </b-dropdown-item>
      <b-dropdown-item @click="getLabelsPerGroup('parent')">
        <feather-icon icon="DownloadIcon" />
        Etiketten voor ouders downloaden per groep
      </b-dropdown-item>
      <b-dropdown-item @click="getLabels('member')">
        <feather-icon icon="DownloadIcon" />
        Etiketten voor {{ getRoleMultiple("member") }} downloaden
      </b-dropdown-item>
    </b-dropdown>
    <b-spinner v-if="loading" class="mr-1" small />
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import userVerification from "@/mixins/userVerification";
import mainMixin from "@/mixins/mainMixin";
import PdfGenerator from "@/components/PdfGenerator.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { db, functions } from "@/firebase/firebaseConfig";
import XLSX from "xlsx";
import moment from "moment";
export default {
  mixins: [userVerification, mainMixin],
  components: {
    PdfGenerator,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    usersData: {
      required: true,
    },
    selectedUsers: {},
  },
  data() {
    return {
      loading: false,
      letterData: [],
      userRecords: null,
      type: "parent",
      titleAddon: "",
    };
  },
  methods: {
    getXLSX() {
      this.loading = true;
      var now = new Date();
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: `Gebruikers ${this.$store.state.organisation.shortName}`,
        Subject: `Geexporteerd op ${this.formatDate(now)}`,
        Author: this.$store.state.organisation.fullName,
        CreatedDate: new Date(),
      };
      wb.SheetNames.push("Gebuikers");

      var ws_data = [
        [
          "Voornaam",
          "Naam",
          "Weergavenaam",
          "Groepen",
          "Email",
          "Telefoonnummer",
        ],
      ];
      for (var i = 0; i < this.selectedUsers.length; i++) {
        const u = this.selectedUsers[i];
        ws_data.push([
          u.firstname,
          u.lastname,
          u.displayName,
          u.groupIds.map((e) => u.groups[e].name).join(", "),
          u.email,
          u.phone,
        ]);
      }
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Gebuikers"] = ws;
      XLSX.writeFile(
        wb,
        `${now.getFullYear()}${now.getMonth()}${now.getDate()}-gebruikers-${
          this.$store.state.organisation.id
        }.xlsx`
      );
      this.loading = false;
    },

    async getLabelsPerGroup(type){
      for(var i = 0; i<this.$store.state.groups.length; i++){
        const g = this.$store.state.groups[i];
        this.userRecords = this.usersData.filter((el) => el.groupIds && el.groupIds.includes(g.id));
        this.titleAddon = g.name;
        await this.getLabels(type);
      }
      this.titleAddon = null;
      this.userRecords = null;
    },

    async getLabels(type) {
      if(this.userRecords == null){
        this.userRecords = this.usersData;
      }
      this.type = type + "Labels";
      this.loading = true;
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Etiketten voor ouders aanmaken gestart`,
          text: "Opgelet, dit kan even duren.",
          icon: "WarningCircleIcon",
          variant: "warning",
        },
      });
      var snap = await db
        .collection("verification")
        .where("organisationId", "==", this.$store.state.organisation.id)
        .where("type", "==", type)
        .get();
      this.letterData = snap.docs.map((d) => d.data());
      setTimeout(async () => {
        try {
          await this.$refs.pdfGenerator.generatePDF();
          this.loading = false;
          this.userRecords = null;
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              text: error.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          this.loading = false;
          this.userRecords = null;
        }
      }, 10);
    },
    async getVerificationLetters() {
      this.loading = true;
      this.userRecords = this.usersData;
      this.type = "parent";
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Brieven aanmaken gestart`,
          text: `Opgelet, afhankelijk van het aantal ${this.getRoleMultiple(
            "member"
          )} kan dit even duren.`,
          icon: "WarningCircleIcon",
          variant: "warning",
        },
      });
      var snap = await db
        .collection("verification")
        .where("organisationId", "==", this.$store.state.organisation.id)
        .where("type", "==", "parent")
        .get();
      this.letterData = snap.docs.map((d) => d.data());
      setTimeout(async () => {
        try {
          await this.$refs.pdfGenerator.generatePDF();
          this.loading = false;
          this.userRecords = null;
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              text: error.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          this.loading = false;
          this.userRecords = null;
        }
      }, 10);
    },
    formatDate(date, format = "dd D/MM/YY (HH:mm)") {
      return moment(date).format(format);
    },
  },
};
</script>

<style>
</style>