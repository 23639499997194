import { functions } from '@/firebase/firebaseConfig';

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const userVerification = {
    methods: {
        async inviteAllMembers() {
            return functions
                .httpsCallable("sendAllMemberInviteMails")({
                    organisationId: this.$store.state.organisation.id,
                }).then((res) => {
                    if (res.data != false) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Verzonden!",
                                text: "De uitnodigingen werden verzonden.",
                                icon: "CheckICon",
                                variant: "success",
                            },
                        });
                    }
                    return;
                }).catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error!",
                            text: error.message,
                            icon: "AlertCirleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                })
        },
        async inviteUser(uoid) {
            return functions
                .httpsCallable("sendMemberInviteMail")({
                    id:
                        uoid,
                    orgId: this.$store.state.organisation.id,
                }).then((res) => {
                    if (res.data != false) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Verzonden!",
                                text: "De uitnodiging werd verzonden.",
                                icon: "CheckICon",
                                variant: "success",
                            },
                        });
                    }
                    return;
                }).catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error!",
                            text: error.message,
                            icon: "AlertCirleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                })
        },
        async inviteContact(contactId) {
            return functions
                .httpsCallable("sendContactInviteMail")({
                    id:
                    contactId,
                    orgId: this.$store.state.organisation.id,
                }).then((res) => {
                    if (res.data != false) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Verzonden!",
                                text: "De uitnodiging werd verzonden.",
                                icon: "CheckICon",
                                variant: "success",
                            },
                        });
                    }
                    return;
                }).catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error!",
                            text: error.message,
                            icon: "AlertCirleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                })
        },
        async getUserCode(uoid) {
            return functions
                .httpsCallable("refreshCode")({
                    uoid:
                        this.u.uoid ||
                        this.u.organisations[this.$store.state.organisation.id].uoid,
                })
        },
        async resetVerificationForOrganisation(){
           return functions
              .httpsCallable("resetVerificationForOrganisation")({
                organisationId: this.$store.state.organisation.id,
              }).then((res) => {
                if(res!=null){
                  this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Succes!`,
                    text: 'De koppelcodes werden opnieuw ingesteld.',
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                return true;
                } else {
                  this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error!`,
                    text: 'Er ging iets fout.',
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
                return false;
                }
              }).catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error!`,
                    text: error.message,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
                return false;
              })
      
          },
    }
}
export default userVerification;